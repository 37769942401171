// $prefix: "app";

// .#{$prefix}-outer-layout {
//   min-height: 100vh;
//   background: #fff;

//   .#{$prefix}-sider {
//     background: #2a3138;
//     position: fixed;
//     left: 0;
//     height: 100vh;
//     overflow-x: hidden;
//     overflow-y: hidden;
//     transition: all 0.3s;
//     // box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
//   }

//   .#{$prefix}-logo {
//     text-align: center;
//     background: #1d2327;
//     height: 64px;
//     z-index: 2;
//     position: relative;
//     box-shadow: 2px 2px 6px 6px rgba(0, 0, 0, 0.1);
//     transition: all 0.3s;
//   }

//   .#{$prefix}-menu {
//     // background: #e6e6e6;
//     background: #2a3138;
//     margin-bottom: 64px;
//     color: #fff;

//     .ant-menu-item .anticon {
//       color: #fff;
//     }

//     .ant-menu-item .ant-menu-item-selected .anticon {
//       color: #fff;
//     }

//     .ant-menu-submenu > .ant-menu {
//       background-color: #1d2327;
//     }

//     .ant-menu-item .anticon + span {
//       color: #fff;
//     }

//     .ant-menu-item-group-title {
//       padding: 0 16px 18px;
//       font-weight: 600;
//       color: #fff;
//       // color: #e8641a;
//     }

//     .item-group {
//       text-transform: uppercase;
//       font-weight: 600;
//     }
//   }

//   .#{$prefix}-inner-layout {
//     transition: all 0.3s;

//     .#{$prefix}-header {
//       background: #2d353c;
//       color: #fff;
//       line-height: 64px;
//       padding: 0px;
//       width: 100%;
//       z-index: 2;
//       position: relative;
//       box-shadow: 2px 2px 6px 6px rgba(0, 0, 0, 0.1);

//       .#{$prefix}-trigger {
//         background: transparent;
//         border: none;
//         outline: none;
//         padding: 0 20px;
//         font-size: 16px;
//         cursor: pointer;
//         transition: all 0.3s;
//       }

//       .#{$prefix}-trigger:hover {
//         color: #fa7252;
//       }

//       .#{$prefix}-index-right {
//         float: right;
//         height: 100%;
//         overflow: hidden;
//         margin-right: 8px;

//         .user-menu {
//           padding: 4px 0;
//           display: inline;
//           cursor: pointer;
//         }
//       }
//     }

//     .#{$prefix}-content {
//       background: #f5f5f5;
//     }

//     .#{$prefix}-footer {
//       background-color: #38424b;
//       color: #fff;
//       box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.08);
//       text-align: center;
//       padding: 16px;

//       p {
//         margin: 0;
//       }

//       .#{$prefix}-footer-subtitle {
//         font-size: 8px;
//       }
//     }
//   }
// }

// .ant-page-header-heading-title {
//   font-size: 16px;
// }

// .container {
//   padding: 16px;
//   .ant-card {
//     border-radius: 0%;
//   }
// }

// .dashboardContainer {
//   table {
//     width: 100%;
//   }
//   .tableStyle,
//   th,
//   td {
//     border: 1px solid black;
//     border-collapse: collapse;
//     padding: 2px;
//   }
//   th,
//   td {
//     padding: 0px;
//     text-align: center;
//   }
//   table.tableStyle tr:nth-child(even) {
//     background-color: #eee;
//   }
//   table.tableStyle tr:nth-child(odd) {
//     background-color: #fff;
//   }
//   table.tableStyle th {
//     background-color: #e8641a;
//     color: white;
//   }
// }

.ant-layout {
  min-height: 100vh;

  .ant-layout-header  {
      height: 64px;
      position: relative;
      box-shadow: 0 1px 10px rgba(0,21,41,.08);
      background-color: #fff;
      .ant-btn .icon-screen-collapse {
          font-size: 21px;
          color: grey;
      }
      .app-header {
          display: flex;
          justify-content: space-between;
          .app-left-header {
              display: flex;
              align-items: center;
          }

          .app-right-header {
              overflow: hidden;
              margin-right: 8px;
              .right-header-content {
                  padding: 4px 0;
                  display: inline;
                  cursor: pointer;
              }
          }
      }
  }

  .app-logo-container {
      display: flex;
      justify-content: center;
  }

  .ant-layout-sider {
      height: auto;
      left: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      box-shadow: 10px 5px 10px rgba(0,21,41,.08);
  }

  .ant-layout-sider-children {
      background-color: #1B1B1B;
  }

  .ant-menu {
      background-color: #1B1B1B;
      
      .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu .ant-menu {
          background-color: #17141E;
      }
  }

  .ant-layout-content {
      background-color: #f0f2f5;
  }

  .ant-layout-footer {
      color: grey;
      text-align: center;
      padding: 16px;
      font-weight: 600;

      p {
          margin: 0;
        }
  
        .ant-footer-subtitle {
          font-size: 10px;
          font-weight: 400;
        }
  }

}

.ant-drawer {
  position: absolute;
  .app-drawer-logo {
      display: flex;
      justify-content: center;
  }
  .ant-drawer-header {
      background-color: #1B1B1B;
      border-color: #1B1B1B;
  }
  .ant-drawer-wrapper-body {
      background-color: #1B1B1B;
  }

  .ant-menu {
      background-color: #1B1B1B;
      
      .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu .ant-menu {
          background-color: #17141E;
      }
  }

  .ant-drawer-body {
      display: flex;
      padding: 0px;

      .ant-menu {
          justify-content: space-between;

      }
  }
}

// Popover items on collapsed sidebar
.ant-menu-submenu  {
  .ant-menu {
      background-color: #17141E;
  }
}

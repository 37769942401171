.ant-page-header {
    background-color: #fff;
    margin-top: 2px;
}

.base-content-container {
    padding: 16px;
    .ant-card {
        border-radius: 0%;
    }
}
.auth-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    .auth-logo-container {
        text-align: center;
    }
    .app-logo {
        margin-bottom: 3vh;
        width: 120px;
        height: 55px;
    }

    .auth-form-container {
        .ant-card {
            border-radius: 0%;
            box-shadow: 1px 1px 10px rgba(0,21,41,.08);
            text-align: center;

            h3, strong {
                font-size: 18px;
            }

            .ant-form {
                margin-top: 25px;
                .ant-form-item-control {
                    width: 300px;
                }

                .ant-form-explain {
                    text-align: left;
                }
            }
           
        }

        .auth-container-small {
            text-align: center;
            .ant-form {
                margin-top: 25px;
                .ant-form-item-control {
                    width: 300px;
                }
                .ant-form-explain {
                    text-align: left;
                }
            }
        }
        .auth-footer {
            margin-top: 2vh;
            text-align: center;
        }
    }
}